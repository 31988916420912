import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import { Subheader } from '@modules'
import { Spacer } from '@elements'

const Index = () => {
  const {
    content: {
      pages: {
        privacy: { sections },
      },
    },
  } = useStateContext()

  return (
    <Container>
      <Subheader data={sections.subheader} />
      <Spacer className="pb-4 lg:pb-8" />
      <Content dangerouslySetInnerHTML={{ __html: sections.content }}></Content>
      <Spacer className="pb-10 md:pb-20 lg:pb-40" />
    </Container>
  )
}

export default Index

const Container = tw.div`w-full bg-background overflow-x-hidden`

const Content = styled.section`
  ${tw`mx-auto max-w-master px-mobile sm:px-tablet lg:px-desktop`}
  h2 {
    ${tw`mb-4 mt-6 text-headlineH3 font-primaryMedium lg:mb-8 lg:mt-20`}
  }
  p {
    ${tw``}
  }
`
